<template>
<report-table>
  <template #thead v-if="rows.length">
    <tr>
      <th scope="col" rowspan="2">
        <span class=" svg-icon svg-icon-secondary svg-icon-sm">
          <inline-svg src="/media/svg/icons/General/Other1.svg" />
        </span>
      </th>
      <th scope="col" rowspan="2">{{ $t('ORDER.START_DATE') }}</th>
      <template v-for="row in rows[0]">
        <th scope="col" colspan="2" :key="row.branchName">
          <router-link target="_blank" class="text-secondary"
                       :to="{ name: 'companyBranchesEdit', params:{id:row.branchUuid} }"
          >
            {{ row.branchName }}
          </router-link>
          <span class=" svg-icon svg-icon-primary svg-icon-sm">
            <inline-svg src="/media/svg/icons/Navigation/Up-right.svg" />
          </span>
        </th>
      </template>
    </tr>
    <tr>
      <template v-for="(row,j) in rows[0]">
        <th :key="row.branchUuid">{{ $t('ORDER.SETUP.QUANTITY') }}</th>
        <th :key="row.branchUuid+j">{{ $t('ORDER.SUMMARY.COST') }}</th>
      </template>
    </tr>
  </template>
  <template #tbody v-if="rows.length">
    <tr :key="i" v-for="(row, i) in rows">
      <td>{{ i+1 }}</td>
      <template v-for="(branch,j) in row">
        {{ add(`count-${branch.branchName}`,branch.count) }}
        {{ add(`cost-${branch.branchName}`,branch.costWithDiscount) }}

        <td v-if="j === 0" :key="branch.date+i">{{ dateFormat(branch.date) }}</td>
        <td :key="branch.branchName+i">{{ branch.count }}</td>
        <td :key="branch.branchName+j+i">{{ branch.costWithDiscount/100 }}</td>
      </template>
    </tr>
    <tr class="text-primary">
      <td colspan="2">{{ $t('REPORTS.TOTAL') }}</td>
      <template v-for="(branch, k) in rows[0]">
        <td :key="branch.branchName">{{ stored(`count-${branch.branchName}`) }}</td>
        <td :key="branch.branchName + k">{{ stored(`cost-${branch.branchName}`)/100 }}</td>
      </template>
    </tr>
  </template>
  <template #tbody v-else>
    <tr>
      <td scope="col" class="text-center">{{ $t('REPORTS.NO_DATA') }}</td>
    </tr>
  </template>
</report-table>
</template>

<script>
import ReportTable from '../table/Table'
import { FORMAT_DATE } from '../../../helpers/validation'

export default {
  name: 'NumServicesByBranchTable',
  components: { ReportTable },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FORMAT_DATE,
      loopStorage: {},
    }
  },
  computed: {
    stored() {
      return (k) => {
        return this.loopStorage[k] || 0
      }
    },
  },
  watch: {
    rows() {
      this.resetLoopStorage()
    },
  },
  methods: {
    dateFormat(val) {
      return this.$moment(val).format(FORMAT_DATE)
    },
    add(k, v) {
      if (this.loopStorage[k] === undefined) {
        this.loopStorage[k] = 0
      }
      this.loopStorage[k] += v
    },
    get(k) {
      return this.loopStorage[k] || 0
    },
    resetLoopStorage() {
      this.loopStorage = {}
    },
  },
}
</script>

<style scoped>
</style>
