<template>
<b-container fluid>
  <div class="row">
    <div class="px-0 col-12 w-100" v-if="rows">
      <div class="card card-custom gutter-b w-100">
        <div class="card-header">
          <div class="card-title">
            <b-input-group class="input-group input-group-sm input-group-solid"
                           style="max-width: 175px"
            >
              <input-form
                v-model="search"
                id="kt_subheader_search_form"
                trim
                type="text"
                debounce="500"
                :placeholder="$t('BASE.SEARCH')"
              />
              <b-input-group-append>
                <span class="input-group-text">
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/General/Search.svg" />
                  </span>
                </span>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="card-toolbar">
            <download-button v-model="loading" v-if="rows.length" :file-name="report.name" :path="downloadPath"
                             :params="downloadParams"
            >
              <inline-svg src="/media/svg/file-extension/xls.svg" />
            </download-button>
            <span class=" svg-icon svg-icon-primary svg-icon-lg btn-clean btn"
                  @click="filterVisible = !filterVisible"
            >
              <inline-svg src="/media/svg/icons/General/Settings-1.svg" class="danger" />
            </span>
          </div>
        </div>
        <b-overlay :show="loading" variants="transparent" class="w-100" opacity="0.4" rounded="sm">
          <report-table :rows="rows" />
        </b-overlay>
      </div>
    </div>
  </div>
  <quick-panel v-model="filterVisible">
    <template #title>{{ $t('BASE.CRITERIA') }}</template>
    <template #body>
      <criteria @populate="populate" />
    </template>
  </quick-panel>
</b-container>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/reportRepository'
import Criteria from '../../../../components/report/num-services-by-branch/Criteria'
import Table from '../../../../components/report/num-services-by-branch/Table'
import DownloadButton from '../../../../components/report/table/DownloadButton'
import InputForm from '../../../../components/forms-items/input'

export default {
  name: 'NumServicesByBranch',
  components: {
    Criteria,
    DownloadButton,
    InputForm,
    ReportTable: Table, 
  },
  mixins: [],
  data() {
    return {
      loading: false,
      search: '',
      filterVisible: false,
      criteria: {},
      criteriaDownload: {},
      report: {
        type: Object,
        default: null,
      },
    }
  },
  computed: {
    downloadPath() {
      return `${Repo.baseUri()}/num-services-by-branch`
    },
    downloadParams() {
      return { ...this.criteria }
    },
    rows() {
      if (!this.report.payload) {
        return []
      }
      return this.report.payload.filter((r) => {
        return r[0].date.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.REPORTS_GROUP') }, { title: this.$t('REPORTS.NUM_SERVICES_BY_BRANCH.TITLE') }])
  },
  methods: {
    populate(criteria) {
      this.loading = true
      this.criteria = criteria
      this.filterVisible = false
      Repo.numServicesByBranch(criteria).then((res) => {
        this.report = res.data.payload.report
        this.report.payload = Object.values(this.report.payload)
      }).catch(() => {
        this.$bvToast.toast(this.$t('TOAST.ERROR'), {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 2000,
          solid: true,
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
